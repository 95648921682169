import React from 'react';
import './TeamMember.css';

function TeamMember(props) {
const { name, role, photoUrl } = props;

return (
<div className="team-member">
<img src={photoUrl} alt={name} className="team-member__photo" width={200} height={200}/>
<h3 className="team-member__name">{name}</h3>
<p className="team-member__role">{role}</p>
</div>
);
}

export default TeamMember;