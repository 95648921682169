import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Home.js'
import Team from './Team';
import Faq from './Faq';
import Download from './Download';
import Roadmap from './Road';
import React from 'react';



function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/team" element={<Team />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/dl" element={<Download />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
