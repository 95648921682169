import React from 'react';
import './Button.css'; // import du fichier CSS pour styler le bouton

function Button(props) {
const { text, color, goUrl } = props;

const buttonStyle = {
backgroundColor: color,
};

return (
    <a className="lika" href={goUrl}> 
<button className="button" style={buttonStyle}>
    {text}
</button>
</a>
);
}

export default Button;

