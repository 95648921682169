import './App.css';
import Navbar from './navbar.js';
import FaqItem from './FaqItem';
import FootBar from './footbar';
import React from 'react';

function Faq() {
  const items = [
    {
      question: "Q: J'aimerais évoquer mon tabou sur Buddies, mais je n'aime pas les réseaux sociaux. Est-ce un probleme ?",
      answer: "A: Bien qu'il reprenne les codes des réseaux les plus populaires, Buddies souhaite avant tout mettre en avant le confort de ses utilisateurs.Jettez un coup d'oeil, vous pourriez être surpris !"
    },
    {
      question: "Q: J'aimerais parler d'un sujet  sensible, mais internet n'est pas tendre de nos jours. Comment m'assurer que je ne vais pas trouver d'ennuis ?",
      answer: "A: La sécurité et le bien-être de nos utilisateurs sont nos principales préoccupations. Nous mettons tout en oeuvre pour que Buddies reste un espace agréable et sain pour tous."
    },
    {
      question: "Q: Je souhaiterais utiliser Buddies, mais je n'ai pas d'ordinateur. Comment faire ?",
      answer: "A: Buddies est disponible sur ordinateur mais aussi smartphone et tablettes, aussi bien sous Android qu'Ios. Vous pouvez télécharger l'application gratuitement sur votre store favori."
    }
  ];
  return (
    <div className="App">
        <Navbar />
      <body  className="App-body">
      <FaqItem title="Foire aux questions" items={items} />
      </body>
      <FootBar />
    </div>
  );
}

export default Faq;
