import './App.css';
import Navbar from './navbar.js';
import Button from './button';
import FootBar from './footbar';
import React from 'react';

function Download() {
  return (
    <div>
        <Navbar />
      <body  className="App-body">
        <div className='centered-container'>
          <a className='pres'>
            Télécharge l'application !
          </a>
          <a>
            actuellement disponible sur Android
          </a>
          <br/>
          <br/>
          <div className='https://drive.google.com/file/d/1eIhpWWTBRthtiHyeehA51AUJv_8G6c-i/view?usp=sharing'>
            <a href='https://drive.google.com/file/d/1eIhpWWTBRthtiHyeehA51AUJv_8G6c-i/view?usp=sharing'>
              <Button text="Télécharger sur Android" />
            </a>
          </div>
        </div>
      </body>
      <FootBar />
    </div>
  );
}

export default Download;
