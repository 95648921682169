import './App.css';
import FootBar from './footbar';
import Navbar from './navbar.js';
import Button from './button';
import React from 'react';

function Home() {
  return (
    <div className="App">
        <Navbar />
      <body  className="App-body">
        <div className='pres'> 
            <a> Bienvenue sur Buddies, le réseau social anonyme pour discuter de sujets tabous en toute sécurité. 
            <br/>     
            <br/> 
            <div className='nj'>
                <Button text="Rejoindre" goUrl="/dl"/>
            </div>
            <br/>     
            Rejoignez notre communauté de soutien pour partager des expériences similaires et s'entraider.
            <br/> 
            <br/> 
            Avec des fonctionnalités telles que la création de contenu anonyme et l'accès à des contenu qui vous correspondes, Buddies est là pour vous aider à vous sentir moins seul.
            </a>
             <img src="example.png" alt="logo" width={250} height={400}/>
        </div>
      </body>
      <FootBar />
    </div>

  );
}

export default Home;
