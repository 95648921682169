import React from 'react';
import FootBar from './footbar';
import Navbar from './navbar.js';
import Button from './button';

function Roadmap() {
  return (
    <div className="App">
      <Navbar />
      <body className="App-body">
        <div
          className="pres"
          style={{ marginLeft: '150px',marginBottom : '100px', textAlign: 'center' }}
        >
          <img src="roadmap.png" alt="logo" width={1300} height={650} />
        </div>
      </body>
      <FootBar />
    </div>
  );
}

export default Roadmap;
