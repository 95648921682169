import React from 'react';
import './footbar.css';

function FootBar() {
  return (
    <footer className="footbar">
      <div className="container">
        <nav className="footbar-nav">
          <ul className="footbar-nav-list">
            <li className="footbar-nav-item">
                <a href="https://instagram.com/buddies_eip?igshid=MzRlODBiNWFlZA=="> @Buddies 2023</a>
            </li>
          </ul>
        </nav>
        <div className="footbar-social">
          <a href="#"><i className="fab fa-facebook-f"></i></a>
          <a href="#"><i className="fab fa-twitter"></i></a>
          <a href="#"><i className="fab fa-instagram"></i></a>
        </div>
      </div>
    </footer>
  );
}

export default FootBar;
