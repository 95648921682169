import React from 'react';
import './Navbar.css';
import Button from './button';

function Navbar() {
  return (
    <nav className="navbar">
      <ul className="nav-list">
        <li>
            <img src="/logo.png" alt="logo" width={50} height={50}/>
        </li>
        <li className="nav-item">
          <a href="/">Accueil</a>
        </li>
        <li className="nav-item">
          <a href="/team">Équipe</a>
        </li>
        <li className="nav-item">
          <a href="/roadmap">Roadmap</a>
        </li>
        <li className="nav-item">
          <a href="/faq">Faq</a>
        </li>
        <li className="nav-item">
          <a href="/dl">Download</a>
        </li>
        <li className="nav-item">
          <a href="http://54.36.191.8:8090/">buddiesDev</a>
        </li>
        <li>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
