import React, { useState } from 'react';
import './FaqItem.css';

function Faq(props) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-header">{props.title}</h2>
      {props.items.map((item, index) => (
        <div className="faq-item" key={index}>
          <button className={`faq-question ${activeIndex === index ? 'faq-active' : ''}`} onClick={() => toggleAccordion(index)}>
            <span>{item.question}</span>
            <span className="faq-icon">{activeIndex === index ? '' : '+'}</span>
          </button>
          {activeIndex === index && (
            <div className="faq-answer">
              <p>{item.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Faq;

