import './App.css';
import TeamMember from './TeamMember';
import Navbar from './navbar.js';
import FootBar from './footbar';
import React from 'react';

function Team() {
  return (
    <div className="App-">
        <Navbar />
      <body  className="team-pres">
        <TeamMember name="Antoine" role="Chef de projet" photoUrl="antoine.jpg"/>
        <TeamMember name="Florian" role="Responsable Ios" photoUrl="florian.jpg"/>
        <TeamMember name="Guillaume" role="Responsable Android" photoUrl="guillaume.png"/>
        <TeamMember name="Arthur" role="Responsable Communication" photoUrl="arthur.jpg"/>
        <TeamMember name="Anthony" role="Responsable Sécurité / Web" photoUrl="anthony.jpeg"/>
        <TeamMember name="Maxence" role="Responsable IA" photoUrl="maxence.jpg"/>
        <TeamMember name="Pierrick" role="Équipe Android" photoUrl="pierrick.jpg"/>
        <TeamMember name="Killian" role="Équipe Ios" photoUrl="killian.jpg"/>
        <TeamMember name="Thomas" role="Équipe Web" photoUrl="thomas.jpg"/>
      </body>
      <FootBar />
    </div>
  );
}

export default Team;
